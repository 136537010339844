import React      from 'react'
import Modal from '../utilities/modal'
import Style from './style.sass'
import Suggests from '../suggests'
import DatePicker    from '../date_picker'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

import { GROUP_TYPES } from './properties'

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class GroupRegister extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      groups: [],
      count: 0,
      type: null,
      target_date: null,
      is_delete_user_stock: false,
    }
  }

  /**
   *  件数チェック
   *  @version 2018/06/10
   */
  _check = e => {

    if (this.refs.suggests && this.refs.suggests.getValue()) {

      const search_form = document.search;

      const type_element = document.createElement('input');
      type_element.setAttribute('type', 'hidden');
      type_element.setAttribute('id', 'type_hidden');
      type_element.setAttribute('name', 'type');
      type_element.setAttribute('value', this.refs.type.value);
      search_form.appendChild(type_element);

      const id_element = document.createElement('input');
      id_element.setAttribute('type', 'hidden');
      id_element.setAttribute('id', 'group_hidden');
      id_element.setAttribute('name', 'group');
      id_element.setAttribute('value', this.refs.suggests.getValue());
      search_form.appendChild(id_element);

      const formData = new FormData(document.search);

      window.startLoading();

      Request.post(`/users/check_companies_count`)
        .send(formData)
        .set('X-Requested-With', 'XMLHttpRequest')
        .withCredentials()
        .setCsrfToken()
        .then(response => {

          if (response.body.count <= 0) {
            window.alertable({ type: 'warning', message: '登録可能な件数は0件です。' });
          }

          this.setState({count: response.body.count}, () => {
            document.getElementById('type_hidden').remove();
            document.getElementById('group_hidden').remove();
            window.closeLoading();
          })
        }).catch(error => {
          window.alertable({ type: 'error', message: 'エラーです' + error.message });
          document.getElementById('type_hidden').remove();
          document.getElementById('group_hidden').remove();
          window.closeLoading();
        });
    } else {
      window.alertable({ type: 'warning', message: '追加するリストを選択してください' });
    }
  }

  /**
   *  submit処理
   *  @version 2018/06/10
   */
  _onSubmit = e => {

    if (this.state.count > this.props.max_count) {
      window.alertable({ type: 'warning', message: '一度にリストに追加できるのは3,000件までです。' });
      return false;
    }

    // 追加するリストが選択されている場合
    if ((this.refs.type.value == 'no_appointment' && this.state.target_date) || this.refs.suggests && this.refs.suggests.getValue()) {

      window.startLoading();

      // 登録する会社(props.company_id)が指定されている場合(会社詳細画面)
      if (this.props.company_id) {

        const search_form = document.createElement('form');
        search_form.action = '/users/companies';
        search_form.method = 'get';
        document.body.appendChild(search_form);

        const company_id_element = document.createElement('input');
        company_id_element.setAttribute('type', 'hidden');
        company_id_element.setAttribute('name', 'id');
        company_id_element.setAttribute('value', this.props.company_id);
        search_form.appendChild(company_id_element);

        const type_element = document.createElement('input');
        type_element.setAttribute('type', 'hidden');
        type_element.setAttribute('name', 'type');
        type_element.setAttribute('value', this.refs.type.value);
        search_form.appendChild(type_element);

        const id_element = document.createElement('input');
        id_element.setAttribute('type', 'hidden');
        id_element.setAttribute('name', 'group');
        id_element.setAttribute('value', this.refs.suggests.getValue());
        search_form.appendChild(id_element);

        search_form.submit();

      // 会社一覧からの登録
      } else {

        const search_form = document.search;

        const type_element = document.createElement('input');
        type_element.setAttribute('type', 'hidden');
        type_element.setAttribute('name', 'type');
        type_element.setAttribute('value', this.refs.type.value);
        search_form.appendChild(type_element);

        const delete_user_stock_element = document.createElement('input');
        delete_user_stock_element.setAttribute('type', 'hidden');
        delete_user_stock_element.setAttribute('name', 'delete_user_stock');
        delete_user_stock_element.setAttribute('value', this.state.is_delete_user_stock);
        search_form.appendChild(delete_user_stock_element);

        // 飛び込み営業日が指定されている場合
        if (this.state.target_date) {

          const target_date_element = document.createElement('input');
          target_date_element.setAttribute('type', 'hidden');
          target_date_element.setAttribute('name', 'target_date');
          target_date_element.setAttribute('value', this.state.target_date);
          search_form.appendChild(target_date_element);

        // 飛び込み営業以外の場合(リストを指定)
        } else {

          const id_element = document.createElement('input');
          id_element.setAttribute('type', 'hidden');
          id_element.setAttribute('name', 'group');
          id_element.setAttribute('value', this.refs.suggests.getValue());
          search_form.appendChild(id_element);
        }

        search_form.submit();
      }
    } else {
      window.alertable({ type: 'warning', message: '追加するリストを選択してください' });
    }
  }

  /**
   *  モーダルを表示する
   *  @version 2018/06/10
   */
  open = () => {

    this.refs.regist_modal.open();
  }

  /**
   *  モーダルを閉じる
   *  @version 2018/06/10
   */
  close = () => {

    this.setState({groups: [], count: 0, type: null});
  }

  /**
   *  グループを取得する
   *  @version 2018/06/10
   */
  get = () => {

    // 飛び込み営業
    if (this.refs.type.value == 'no_appointment') {
      
      this.setState({type: this.refs.type.value});

    // 飛び込み以外
    } else if (this.refs.type.value) {

      window.startLoading();

      // リスト情報の取得
      Request.get(`/users/groups/get_groups?type=${this.refs.type.value}`)
        .set('X-Requested-With', 'XMLHttpRequest')
        .setCsrfToken()
        .end((error, response) => {

          if (response.body.status == 'success' && response.body.groups) {

            const groups = response.body.groups.map((group) => {

              let name = group.name;
              name = `${group.id}:${name}`;
              if (group.prefecture) name = `${name}:${group.prefecture}`;

              return {name: name};
            });

            this.setState({groups: groups, count: 0, type: this.refs.type.value}, () => {
              window.closeLoading();
            });
          } else {
            this.setState({groups: [], count: 0, type: null}, () => {
              window.closeLoading();
              window.alertable({ type: 'error', message: 'エラーによりリスト情報が取得できませんでした。' });
            });
          }
        });
    } else {
      this.setState({groups: [], count: 0, type: null});
    }
  }

  /**
   *  飛び込み営業日
   *  @version 2018/06/10
   */
  setTargetDate = datetime => {
    this.setState({target_date: datetime.datetime.format('YYYY-MM-DD')})
  }

  /**
   *  グレーアウト条件
   *  @version 2018/06/10
   */
  grayout_contents() {

    // 譲受企業開拓
    if (['tel_buyer', 'tel_buyer_advisory_done'].includes(this.refs.type.value)) {

      return (
        <div className='u-mt-5 c-attention u-fs-small'>
          <p>・「譲渡企業」として登録されている企業</p>
          <p>・「譲受企業」として登録されており、かつ「ディール中」となっている企業</p>
          <p>・「送信制限中」の企業</p>
          <p>・法人番号なし</p>
          <p>・提携先パートナーから紹介された企業</p>
        </div>
      );

    // 譲渡企業開拓
    } else if (['seller', 'mail_seller', 'president', 'accountant', 'carve_out', 'unlimit', 'project', 'project_president', 'kensetsu', 'kensetsu_mail', 'kensetsu_president', 'touhoku', 'touhoku_mail', 'touhoku_president', 'financial', 'financial_mail', 'financial_president', 'executive', 'executive_mail', 'executive_president', 'quants', 'quants_mail', 'quants_president'].includes(this.refs.type.value)) {

      return (
        <div className='u-mt-5 c-attention u-fs-small'>

          { ['seller', 'accountant', 'kensetsu', 'touhoku', 'financial', 'executive', 'quants'].includes(this.refs.type.value) ?
            <React.Fragment>
              <p>・現在依頼中のリスト内の企業(重複送信の防止)</p>
              <p>・DMにて30日以内に「送信済み」ステータスになった企業</p>
              <p>・AD契約済の企業</p>
              <p>・DMにて「<span className='u-fw-bold u-fc-red'>送信不能</span>」ステータスになった企業</p>
              <p>・DMにて「<span className='u-fw-bold u-fc-red'>営業禁止</span>」ステータスになった企業</p>
              <p>・ストロングバイヤー(SBフラグあり)</p>
              <p>・上場企業</p>
              <p>・消滅会社</p>
              <p>・「譲渡企業」として登録されている企業</p>
              <p>・「譲受企業」として登録されている企業</p>
              <p>・「送信制限中」の企業</p>
              <p>・パートナー提携先から紹介された企業</p>
            </React.Fragment>
            : null
          }

          { ['mail_seller', 'kensetsu_mail', 'touhoku_mail', 'financial_mail', 'executive_mail', 'quants_mail'].includes(this.refs.type.value) ?
            <React.Fragment>
              <p>・手紙にて45日以内に「送信済み」ステータスになった企業</p>
              <p>・AD契約済の企業</p>
              <p>・ストロングバイヤー(SBフラグあり)</p>
              <p>・上場企業</p>
              <p>・消滅会社</p>
              <p>・宛先が不着</p>
              <p>・「譲渡企業」として登録されている企業</p>
              <p>・「譲受企業」として登録されている企業</p>
              <p>・「送付制限中」の企業</p>
              <p>・パートナー提携先から紹介された企業</p>
            </React.Fragment>
            : null
          }

          { ['president', 'kensetsu_president', 'touhoku_president', 'financial_president', 'executive_president', 'quants_president'].includes(this.refs.type.value) ?
            <React.Fragment>
              <p>・社長手紙にて45日以内に「送信済み」ステータスになった企業</p>
              <p>・AD契約済の企業</p>
              <p>・ストロングバイヤー(SBフラグあり)</p>
              <p>・上場企業</p>
              <p>・消滅会社</p>
              <p>・宛先が不着</p>
              <p>・「譲渡企業」として登録されている企業</p>
              <p>・「譲受企業」として登録されている企業</p>
              <p>・「送付制限中」の企業</p>
              <p>・パートナー提携先から紹介された企業</p>
            </React.Fragment>
            : null
          }

          { ['buyer'].includes(this.refs.type.value) ?
            <React.Fragment>
              <p>・DMにて15日以内に「送信済み」ステータスになった企業</p>
              <p>・DMにて「<span className='u-fw-bold u-fc-red'>送信不能</span>」ステータスになった企業</p>
              <p>・DMにて「<span className='u-fw-bold u-fc-red'>営業禁止</span>」ステータスになった企業</p>
              <p>・「譲渡企業」として登録されている企業</p>
              <p>・「送信制限中」の企業</p>
              <p>・パートナー提携先から紹介された企業</p>
            </React.Fragment>
            : null
          }

          { ['carve_out'].includes(this.refs.type.value) ?
            <React.Fragment>
              <p>・DMにて「<span className='u-fw-bold u-fc-red'>営業禁止</span>」ステータスになった企業</p>
              <p>・窓口担当者が登録済みの企業</p>
              <p>・総研担当者が登録されている企業</p>
              <p>・AD契約済の企業</p>
              <p>・ストロングバイヤー(SBフラグあり)</p>
              <p>・ディール中の企業</p>
              <p>・消滅会社</p>
              <p>・「譲渡企業」として登録されている企業</p>
              <p>・「送信制限中」の企業</p>
              <p>・パートナー提携先から紹介された企業</p>
            </React.Fragment>
            : null
          }

          { ['unlimit'].includes(this.refs.type.value) ?
            <React.Fragment>
              <p>・DMにて「営業禁止」ステータスになった企業</p>
              <p>・AD契約済の企業</p>
              <p>・ストロングバイヤー(SBフラグあり)</p>
              <p>・上場企業</p>
              <p>・消滅会社</p>
              <p>・「譲渡企業」として登録されている企業</p>
              <p>・「譲受企業」として登録されている企業</p>
              <p>・「送信制限中」の企業</p>
              <p>・パートナー提携先から紹介された企業</p>
            </React.Fragment>
            : null
          }

          { ['project'].includes(this.refs.type.value) ?
            <React.Fragment>
              <p>・ストロングバイヤー(SBフラグあり)</p>
              <p>・AD契約済の企業</p>
              <p>・上場企業</p>
              <p>・消滅会社</p>
              <p>・「譲渡企業」として登録されている企業</p>
              <p>・「送付制限中」の企業</p>
              <p>・パートナー提携先から紹介された企業</p>
            </React.Fragment>
            : null
          }

          { ['project_president'].includes(this.refs.type.value) ?
            <React.Fragment>
              <p>・ストロングバイヤー(SBフラグあり)</p>
              <p>・AD契約済の企業</p>
              <p>・上場企業</p>
              <p>・消滅会社</p>
              <p>・「譲渡企業」として登録されている企業</p>
              <p>・「送付制限中」の企業</p>
              <p>・パートナー提携先から紹介された企業</p>
              <p>・代表者住所不明</p>
            </React.Fragment>
            : null
          }
        </div>
      );
    }
  }

  /**
   *  アラートに表示するコンテンツ
   *  @version 2018/06/10
   */
  content() {

    return (
      <div className={Style.GroupRegister}>
        <p className='u-fs-large u-mt-30'>リストの種別を選択</p>
        <div className='c-form-selectWrap u-mt-10'>
          <select className='c-form-select' ref='type' defaultValue='' name='type' onChange={this.get}>
            <option value=''>選択してください</option>
            { GROUP_TYPES.map((type, index) => {
              const key = 'type-'+index;
              return (
                <option {...{key}} value={type.value}>{type.name}</option>
              );
            })}
          </select>
        </div>
        { this.state.groups.length > 0 ?
          <div>
            <p className='u-fs-large u-mt-15'>追加するリストを選択</p>
            <div className='u-mt-10'>
              <Suggests candidates={this.state.groups} ref='suggests'/>
            </div>
          </div>
          :
          <div className='u-mt-30'>
            { this.state.type == 'no_appointment' ?
              <div>
                <p>飛び込み営業を行う日付を選択してください。</p>
                <div>
                  <DatePicker type='text' apply={this.setTargetDate} />
                </div>
                <div className='c-btnMain-primary u-display-block u-mt-15' onClick={this._onSubmit}>飛び込み営業リストに追加する</div>
              </div>
              :
              <div>
                <p>リストの種別を選択してください。</p>
                <div className='c-attention u-mt-10 u-fs-small'>
                登録できるリストが存在しません。追加するリストを先に作成しておく必要があります。
                </div>
              </div>
            }
          </div>
        }

        <div className='u-ta-left u-mt-20'>
          { this.state.count > 0 ?
            <div>
              <div className='c-attention u-mb-15'>
                リストに登録できる件数は<span className='u-fc-red u-fw-bold'>{this.state.count}</span>件です。
                { this.state.count > this.props.max_count ?
                  <p className='u-fc-red'>※一度にリストに追加できるのは3,000件までです。</p>
                  : null
                }
              </div>
              <p className='u-fs-small'>※下記の除外条件にあてはまる企業を除外した件数です。</p>
              {this.grayout_contents()}
              { this.props.user_stock_deletable ?
                <div className='u-mt-15'>
                  <label className='c-form-checkboxLabel'>
                    <input className='c-form-checkbox' type='checkbox' checked={this.state.is_delete_user_stock} onChange={() => { this.setState({is_delete_user_stock: !this.state.is_delete_user_stock}) }} />
                    <i className='c-form-checkboxIcon'></i>
                    <span className='u-ml-5'>登録時にストックを解除する</span>
                  </label>
                </div>
                : null
              }
              <div className='c-btnMain-primary u-display-block u-mt-15' onClick={this._onSubmit}>リストに登録する</div>
            </div>
            :
            <div>
              {this.props.count == 1 ?
                <div>
                  { this.props.user_stock_deletable && this.state.groups.length > 0 ?
                    <div className='u-mt-15'>
                      <label className='c-form-checkboxLabel'>
                        <input className='c-form-checkbox' type='checkbox' checked={this.state.is_delete_user_stock} onChange={() => { this.setState({is_delete_user_stock: !this.state.is_delete_user_stock}) }} />
                        <i className='c-form-checkboxIcon'></i>
                        <span className='u-ml-5'>登録時にストックを解除する</span>
                      </label>
                    </div>
                    : null
                  }
                  <div className='c-btnMain-primary u-display-block u-mt-15' onClick={this._onSubmit}>リストに登録する</div>
                </div>
                :
                <div>
                  { this.state.type != 'no_appointment' ?
                    <div className='c-btnMain-standard u-display-block u-mt-15' onClick={this._check}>登録の事前チェック</div>
                    : null
                  }
                </div>
              }
            </div>
          }
        </div>
      </div>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div>
        <span onClick={this.open} className='c-btnMain-primary'>ソーシングリストに追加</span>
        <Modal ref='regist_modal' yield={this.content()} close_callback={this.close}/>
      </div>
    );
  }
}
